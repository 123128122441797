import gql from 'graphql-tag';
import { api } from '../../config';
import { toastr } from 'react-redux-toastr';
import {
  CAR_IMAGE_UPLOAD_ERROR,
  CAR_IMAGE_UPLOAD_START,
  CAR_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'

export default function uploadCarFrontImage(id, carFront, oldImageFront) {

    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CAR_IMAGE_UPLOAD_START
        })

        try {
            const mutation = gql`
            mutation uploadCarFrontImage($userId:ID,$carFront:String){
                uploadCarFrontImage(userId:$userId, carFront:$carFront) {
                  status
                }
              }
            `
            const { data } = await client.mutate({
                mutation,
                variables: {
                    userId: id,
                    carFront
                }
            })

            if (oldImageFront !== undefined) {
                removeCarImage(oldImageFront);
            };

            console.log('data', data);
            
            if(data && data.uploadCarImage && data.uploadCarImage.status == "200") {
                dispatch({
                    type: CAR_IMAGE_UPLOAD_SUCCESS
                })
                toastr.success('Success', 'Licence images has been uploaded!')
            } else {
                dispatch({
                    type: CAR_IMAGE_UPLOAD_ERROR
                })
            }
        } catch (err) {
            dispatch({
                type: CAR_IMAGE_UPLOAD_ERROR
            })
        }
    }
}

async function removeCarImage(fileName) {
    try {
      const url = api.apiEndpoint + "/deleteCarImage";
      const resp = await fetch(url, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
      });
  
      const { status } = await resp.json();
  
      if (status) {
        return { 
          status
        };
    }
  
    } catch (err) {
      console.log(err);
    }
  }